$textColor: #fff;

#footer {
    background-image: url("../../public/img/bg-3.jpg");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    padding: 2em;
    line-height: 1.5;

    & p {
        text-align: center;
        color: $textColor;
        margin: 1em;
    }

    & a {
        color: $textColor;
        text-decoration: none;
    }
}