#contest-structure {
    position: relative;
    width: 100%;
    padding: 2em;
    line-height: 1.5;

    &::before {
        content: "";
        background-image: url("../../public/img/bg-2.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    & img {
        width: 100%;
    }

    & p {
        text-align: justify;
        margin-bottom: 1em;
    }

    & .general-info {

        & .headline {
            text-align: center;
    
            & img {
                max-width: 260px;

                @media (min-width: 600px) and (max-width: 900px) {
                    max-width: 300px;
                }
        
                @media (min-width: 901px) {
        
                }
            }
        }

        & .content {
            padding: 0;

            @media (min-width: 600px) and (max-width: 900px) {
                padding: 0 2em;
            }
    
            @media (min-width: 901px) {
    
            }
        }

    }

    & .structure {
        margin-top: 2em;

        & .headline {
            text-align: center;
    
            & img {
                max-width: 300px;

                @media (min-width: 600px) and (max-width: 900px) {
                    max-width: 350px;
                }
        
                @media (min-width: 901px) {
        
                }
                
            }
        }
        
        & .content {
            padding: 0;

            @media (min-width: 600px) and (max-width: 900px) {
                padding: 0 2em;
            }
    
            @media (min-width: 901px) {
    
            }
        }
    }

    & .seemore {
        text-align: center;
        cursor: pointer;
        
        & img {
            max-width: 180px;

            @media (min-width: 600px) and (max-width: 900px) {
                max-width: 250px;
            }
    
            @media (min-width: 901px) {
    
            }
        }
    }
}