#contest-honorboard {
    min-height: 350px;
    padding: 5em 0;
    line-height: 1.5;

    background-image: url("../../public/img/wavingbg.png");
    background-repeat: no-repeat;
    background-position: center;

    & h2 {
        text-align: center;
        margin-bottom: 1em;
    }

    & p {
        text-align: justify;
        margin-bottom: 1em;
    }

    & .result {
        margin-top: 1em;
    }

    & .grade-title {
        margin-bottom: 0.5em;
    }

    & .result-row {
        display: flex;
        flex-direction: column;
        gap: 0.5em 1em;

        & .result-item {
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 0.5em;
            background-color: #fff;

            & .medal {
                margin-bottom: 1em;
                padding: 1em;
                border-bottom: 1px dashed #ddd;
                font-weight: bolder;
            }
    
            & .student-image {
                max-width: 150px;
    
                & img {
                    width: 100%;
                }
            }

            & .info {
                display: flex;
                gap: 0.5em 1em;
            }
        }
    }
}
    