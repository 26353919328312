#contest-instructions {
    width: 100%;
    padding: 2em;
    line-height: 1.5;

    & img {
        width: 100%;
    }

    & p {
        text-align: justify;
        margin-bottom: 1em;
    }

    & .instructions {
        & .headline {
            text-align: center;
    
            & img {
                max-width: 300px;

                @media (min-width: 600px) and (max-width: 900px) {
                    max-width: 350px;
                }
        
                @media (min-width: 901px) {
        
                }
            }
        }

        & .content {
            padding: 0;

            @media (min-width: 600px) and (max-width: 900px) {
                padding: 0 2em;
            }
    
            @media (min-width: 901px) {
    
            }
        }
    }

    & .timeline {
        margin-top: 2em;
        
        & .headline {
            text-align: center;
    
            & img {
                max-width: 250px;
            }
        }

        & .content {
            & .contest-date {
                font-weight: bold;
            }

            & .contest-time {
                margin-top: 3em;
                font-weight: bold;
            }
        }
    }
}