$textColor: #fff;

#contest-intro {
    background-image: url("../../public/img/bg-1.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;

    & img {
        width: 100%;
    }
    
    & .floating-icons {
        position: relative;
        
        & #el-1 {
            width: 126px;
            height: 111px;
            position: absolute;
            top: 50px;
            left: 50px;

            @media (max-width: 900px) {
                display: none;
                width: 82px;
                height: 72px;
                top: 220px;
                left: 30px;
            }
        }

        & #el-2 {
            width: 126px;
            height: 157px;
            position: absolute;

            @media (max-width: 900px) {
                display: none;
            }

            @media (min-width: 901px) and (max-width: 1200px) {
                top: 35em;
                right: 5em;
            }
    
            @media (min-width: 1200px) {
                top: 25em;
                left: 5em;
            }
        }

        & #el-3 {
            width: 98px;
            height: 73px;
            position: absolute;
            top: 50px;
            right: 50px;

            @media (max-width: 900px) {
                display: none;
            }

            @media (min-width: 901px) and (max-width: 1200px) {
                top: 10em;
                right: 5em;
            }
    
            @media (min-width: 1200px) {
                top: 25em;
                right: 10em;
            }
        }

        & #el-4 {
            width: 89px;
            height: 78px;
            position: absolute;

            @media (max-width: 900px) {
                display: none;
            }

            @media (min-width: 901px) and (max-width: 1200px) {
                display: none;
            }
    
            @media (min-width: 1200px) {
                top: 38em;
                right: 3em;
            }
        }

    }

    & .contest-logo {
        position: relative;
        width: 100%;
        text-align: center;
        
        & img {
            max-width: 180px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

    }

    & .contest-name {
        position: relative;
        max-width: 800px;
        margin: 0 auto;

        & h1 {
            text-transform: uppercase;
            color: $textColor;
            text-align: center;
            padding: 0 1.5em;
            line-height: 1.5;

            @media (min-width: 600px) and (max-width: 900px) {
                padding: 0;
            }
    
            @media (min-width: 901px) {
    
            }
        }

        & img {
            padding: 2em 2.5em 0;
            transform: translateY(-2.5em);

            @media (min-width: 600px) and (max-width: 900px) {
                padding: 1.5em 6.5em 0;
                transform: translateY(-2.5em);
            }
    
            @media (min-width: 901px) {
    
            }
            
        }

    }

    & .contest-intro-text {
        position: relative;
        width: 100%;
        padding: 0 2em;
        color: $textColor;
        line-height: 1.5;
        text-align: justify;
        max-width: 1200px;
        margin: 0 auto;

        @media (min-width: 600px) and (max-width: 900px) {
            padding: 0 4em;
        }

        @media (min-width: 901px) {
            
        }

        & .contest-intro-text-wrapper {
            @media (min-width: 600px) and (max-width: 900px) {

            }
    
            @media (min-width: 901px) and (max-width: 1200px) {
                max-width: 450px;
                transform: translateX(25em);
            }
    
            @media (min-width: 1200px) {
                max-width: 500px;
                transform: translateX(36em);
            }
        }

        & p {
            margin-bottom: 1em;
        }

        & p:last-child {
            max-width: 55%;

            @media (min-width: 600px) and (max-width: 900px) {
                max-width: 60%;
            }
    
            @media (min-width: 901px) {
                max-width: 100%;
            }
        }

        & .contest-kid {
            position: absolute;
            max-width: 220px;
            bottom: 0;
            right: 0;

            @media (min-width: 600px) and (max-width: 900px) {
                right: 3.5em;
            }
    
            @media (min-width: 901px) and (max-width: 1200px) {
                right: initial;
                bottom: 5em;
                max-width: 350px;
            }
    
            @media (min-width: 1200px) {
                right: initial;
                bottom: -5em;
                max-width: 500px;
            }
        }

        & .seemore {
            max-width: 180px;
            transform: translateX(-1.25em);
            cursor: pointer;

            @media (min-width: 600px) and (max-width: 900px) {
                max-width: 250px;
                transform: translateX(-1.5em);
            }

            @media (min-width: 901px) and (max-width: 1200px) {
                max-width: 250px;
                transform: translateX(23.5em);
            }
    
            @media (min-width: 1200px) {
                max-width: 250px;
                transform: translateX(34.5em);
            }
        }
    }

}