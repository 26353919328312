#ads-mlo-popup {
    position: fixed; 
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    top: 5em;

    @media (min-width: 750px) {
        width: initial;
        margin: initial;
        top: initial;
        bottom: 1em;
        left: 1.5em;
    }
    
    & .tablecell {
        display: table-cell;
        vertical-align: middle;
    }

    & #ads-mlo-banner {
        border: 1px solid #d0533f;    
        background-color: #fff;
        cursor: pointer;
        position: relative;
        width: 400px;
        height: 116px;
        margin: 10px auto;

        & #ads-mlo-tranbinh-img {
            width: 35%;
            & img{
                max-width: 125px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        
        & #ads-mlo-content{
            font-weight: bold;
            color: #d0533f;
            text-align: left;
            line-height: 1.5;
        
            & p {
                margin-bottom: 0;
            }
        }

        & #ads-close {
            position: absolute;
            top: -26px;
            right: -1px;
            border: 1px solid;
            padding: 0px 10px;
            background-color: #fff;
        }
    }
}





