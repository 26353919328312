#contest-result {
    min-height: 350px;
    padding: 5em 0;
    line-height: 1.5;

    background-image: url("../../public/img/wavingbg.png");
    background-repeat: no-repeat;
    background-position: center;

    & h2 {
        text-align: center;
        margin-bottom: 1em;
    }

    & p {
        text-align: justify;
        margin-bottom: 1em;
    }

    & .result {
        margin-top: 1em;
    }

    & #search-result {
        padding: 1em;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

        & .round-divider {
            display: table;
            width: 100%;
            height: 1em;
            border-top: 1px dashed #ddd;
        }

        & .answers-table {
            & td {
                text-align: center;
            }

            & .correct {
                background-color: palegreen;
            }

            // & .wrong {
            //     background-color: red;
            // }

        }

        & #share-result {
            margin: 1em 0;

            & a {
                border: 1px solid #ddd;
                border-radius: 5px;
                background-color: #fff;
                box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
                padding: 0.5em 0.75em;
                text-decoration: none;
            }
        }
    }
}