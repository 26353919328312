$headlineColor: rgb(255, 245, 90);
$textColor: #fff;

#contest-gifts {
    position: relative;
    width: 100%;
    padding: 2em;
    line-height: 1.5;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        z-index: -1; // Ensure the backgrounds are behind the content
    }

    &::before {
        background-image: url("../../public/img/bg-3.jpg");
        opacity: 1;
    }

    &::after {
        background-image: url("../../public/img/texture-1.png");
        opacity: 1;
    }
    
    & img {
        width: 100%;
    }
    
    & p {
        text-align: justify;
        color: $textColor;
        margin-bottom: 0.25em;
    }

    & .headline {
        text-align: center;

        & img {
            max-width: 300px;

            @media (min-width: 600px) and (max-width: 900px) {
                max-width: 350px;
            }
    
            @media (min-width: 901px) {
    
            }
        }
    }

    & .container {
        max-width: 1200px;
    }

    & .flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 1200px) {
            display: initial;
        }
    }

    & .gift-headline {
        color: $headlineColor;
        font-size: 26px;
        padding: 1em 0.55em 0;

        @media (min-width: 600px) and (max-width: 900px) {
            padding: 1em 1.25em 0;
        }

        @media (min-width: 901px) {

        }
    }

    & .gift-detail {
        padding: 1em;

        @media (min-width: 600px) and (max-width: 900px) {
            padding: 0 2em;
        }

        @media (min-width: 901px) {

        }

        @media (min-width: 1201px) {
            width: 60%;
        }
    }

    & .winner {
        
        & .gift-images {
            position: relative;
            height: 350px;
            max-width: 70%;

            @media (max-width: 480px) {
                scale: 0.9;
                transform: translateX(-2.5em);
            }

            @media (min-width: 481px) and (max-width: 600px) {
                scale: 1;
                transform: translateX(2.5em);
            }

            @media (min-width: 601px) and (max-width: 768px) {
                scale: 1;
                transform: translateX(5.5em);
            }

            @media (min-width: 769px) and (max-width: 900px) {
                scale: 1;
                // transform: translateX(5.5em);
            }

            & img {
                position: absolute;
                
            }

            & .tablet {
                z-index: 2;
                width: 160px;
                top: 50px;
                left: 110px;
            }

            & .bag-1 {
                z-index: 3;
                width: 160px;
                bottom: 18px;
                left: 45px;
            }

            & .book-1 {
                z-index: 1;
                width: 90px;
                top: 88px;
                left: 55px;
            }

            & .book-2 {
                z-index: 1;
                width: 110px;
                top: 77px;
                left: 235px;

            }

            & .book-3 {
                z-index: 4;
                width: 95px;
                bottom: 20px;
                left: 215px;
            }

            & .book-4 {
                z-index: 3;
                width: 95px;
                bottom: 30px;
                left: 195px;
            }

            & .medals {
                z-index: 5;
                width: 95px;
                bottom: 30px;
                left: 295px;
            }

            & .shelf {
                width: 400px;
                bottom: 0;
            }
        }
    }

    & .runner-up {
        & .gift-images {
            position: relative;
            height: 350px;
            max-width: 70%;
            
            @media (max-width: 480px) {
                scale: 0.9;
                transform: translateX(-2.5em);
            }

            @media (min-width: 481px) and (max-width: 600px) {
                scale: 1;
                transform: translateX(2.5em);
            }

            @media (min-width: 601px) and (max-width: 768px) {
                scale: 1;
                transform: translateX(5.5em);
            }

            @media (min-width: 769px) and (max-width: 900px) {
                scale: 1;
                // transform: translateX(5.5em);
            }         

            & img {
                position: absolute;
                
            }

            & .headphone-1 {
                z-index: 2;
                width: 140px;
                top: 50px;
                left: 110px;
            }

            & .wristband-1 {
                z-index: 2;
                width: 70px;
                top: 120px;
                left: 210px;
            }

            & .bag-1 {
                z-index: 3;
                width: 160px;
                bottom: 18px;
                left: 45px;
            }

            & .book-1 {
                z-index: 1;
                width: 90px;
                top: 88px;
                left: 55px;
            }

            & .book-2 {
                z-index: 1;
                width: 110px;
                top: 77px;
                left: 235px;

            }

            & .book-3 {
                z-index: 4;
                width: 95px;
                bottom: 20px;
                left: 215px;
            }

            & .book-4 {
                z-index: 3;
                width: 95px;
                bottom: 30px;
                left: 195px;
            }

            & .medals {
                z-index: 5;
                width: 95px;
                bottom: 30px;
                left: 295px;
            }

            & .shelf {
                width: 400px;
                bottom: 0;
            }
        }
    }

    & .third-place {
        & .gift-images {
            position: relative;
            height: 350px;
            max-width: 70%;

            @media (max-width: 480px) {
                scale: 0.9;
                transform: translateX(-2.5em);
            }

            @media (min-width: 481px) and (max-width: 600px) {
                scale: 1;
                transform: translateX(2.5em);
            }

            @media (min-width: 601px) and (max-width: 768px) {
                scale: 1;
                transform: translateX(5.5em);
            }

            @media (min-width: 769px) and (max-width: 900px) {
                scale: 1;
                // transform: translateX(5.5em);
            }

            & img {
                position: absolute;
                
            }

            & .headphone-2 {
                z-index: 2;
                width: 140px;
                top: 50px;
                left: 125px;
            }

            & .bag-1 {
                z-index: 3;
                width: 160px;
                bottom: 18px;
                left: 45px;
            }

            & .book-1 {
                z-index: 1;
                width: 90px;
                top: 88px;
                left: 55px;
            }

            & .book-2 {
                z-index: 1;
                width: 110px;
                top: 77px;
                left: 235px;

            }

            & .book-3 {
                z-index: 4;
                width: 95px;
                bottom: 20px;
                left: 215px;
            }

            & .book-4 {
                z-index: 3;
                width: 95px;
                bottom: 30px;
                left: 195px;
            }

            & .medals {
                z-index: 5;
                width: 95px;
                bottom: 30px;
                left: 295px;
            }

            & .shelf {
                width: 400px;
                bottom: 0;
            }
        }
    }

    & .fourth-place {
        & .gift-images {
            position: relative;
            height: 350px;
            max-width: 70%;
            
            @media (max-width: 480px) {
                scale: 0.9;
                transform: translateX(-2.5em);
            }

            @media (min-width: 481px) and (max-width: 600px) {
                scale: 1;
                transform: translateX(2.5em);
            }

            @media (min-width: 601px) and (max-width: 768px) {
                scale: 1;
                transform: translateX(5.5em);
            }

            @media (min-width: 769px) and (max-width: 900px) {
                scale: 1;
                // transform: translateX(5.5em);
            }

            & img {
                position: absolute;
                
            }

            & .book-1 {
                z-index: 1;
                width: 170px;
                bottom: 100px;
                left: 155px;
            }

            & .book-3 {
                z-index: 2;
                width: 125px;
                bottom: 125px;
                left: 60px;
            }

            & .book-4 {
                z-index: 3;
                width: 125px;
                bottom: 115px;
                left: 100px;
            }

            & .shelf {
                width: 400px;
                bottom: 0;
            }
        }
    }

    & .certificate {
        display: none;

        & .gift-images {
            text-align: center;
            
            @media (min-width: 600px) and (max-width: 900px) {
                text-align: unset;
                padding: 0.75em 1.75em;
            }
    
            @media (min-width: 901px) {
    
            }
        }

        & img {
            max-width: 200px;
        }
    }
}